<template>
	<v-alert :outline="outline" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</v-alert>
</template>

<script>
/**
 * @display w-alert
 *
 * @since 0.3.2
 */
export default {
	name: 'WAlert',
	props: {
		outline: {
			default: false,
			required: false,
			type: Boolean
		}
	}
}
</script>
